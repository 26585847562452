import { PerspectiveCamera } from "three";
import sizes from "~/utils/sizes";

let camera;

export { camera };

export const init = () => {
  camera = new PerspectiveCamera(45, 0, 0.1, 500);

  resize();
  sizes.on("resize", resize);
};

export const updateOffset = (offset) => {
  camera.setViewOffset(sizes.width, sizes.height, offset.x, offset.y, sizes.width, sizes.height);
  camera.updateProjectionMatrix();
};

const resize = () => {
  if (!camera) return;
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();
};
