export default {
  10000: {
    uvOffset: [0, 0],
    unlocked: true,
    view: {
      bg: "#D9D9D9",
    },
  },
  10001: {
    uvOffset: [0.015625, 0],
    unlocked: true,
    view: {
      bg: "#6E3D3A",
    },
  },
  10002: {
    uvOffset: [0.03125, 0],
    unlocked: true,
    view: {
      bg: "#7D4A3F",
    },
  },
  10003: {
    uvOffset: [0.046875, 0],
    unlocked: true,
    view: {
      bg: "#8C4A25",
    },
  },
  10004: {
    uvOffset: [0.0625, 0],
    unlocked: true,
    view: {
      bg: "#97513F",
    },
  },
  10005: {
    uvOffset: [0.078125, 0],
    unlocked: true,
    view: {
      bg: "#985C30",
    },
  },
  10006: {
    uvOffset: [0.09375, 0],
    unlocked: true,
    view: {
      bg: "#A46648",
    },
  },
  10007: {
    uvOffset: [0.109375, 0],
    unlocked: true,
    view: {
      bg: "#B76E44",
    },
  },
  10008: {
    uvOffset: [0.125, 0],
    unlocked: true,
    view: {
      bg: "#C6775C",
    },
  },
  10009: {
    uvOffset: [0.140625, 0],
    unlocked: true,
    view: {
      bg: "#E18E70",
    },
  },
  10010: {
    uvOffset: [0.15625, 0],
    unlocked: true,
    view: {
      bg: "#E59D65",
    },
  },
  10011: {
    uvOffset: [0.171875, 0],
    unlocked: true,
    view: {
      bg: "#F2A07D",
    },
  },
  10012: {
    uvOffset: [0.1875, 0],
    unlocked: true,
    view: {
      bg: "#FFB89D",
    },
  },
  10013: {
    uvOffset: [0.203125, 0],
    unlocked: true,
    view: {
      bg: "#FFC6B7",
    },
  },
  10014: {
    uvOffset: [0.21875, 0],
    unlocked: true,
    view: {
      bg: "#FFCBA3",
    },
  },
  10015: {
    uvOffset: [0.234375, 0],
    unlocked: true,
    view: {
      bg: "#FFE2D6",
    },
  },
};
