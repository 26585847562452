import {
  Matrix4,
  Vector3,
  Quaternion,
  Mesh,
  Euler,
  Group,
  BufferGeometry,
  Float32BufferAttribute,
  ShaderMaterial,
  BackSide,
} from "three";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import { v4 as uuidv4 } from "uuid";
import resources from "../../../utils/resources";
import Limb from "./limb.js";
import customization from "../../../../constants/customization/index.js";
import { getMaterial, returnMaterial } from "./materials.js";
import outlineFragmentShader from "../../shaders/avatar/outline/fragment.glsl";
import outlineVertexShader from "../../shaders/avatar/outline/vertex.glsl";

export const rigData = [
  { id: 0, name: "body" },
  { id: 1, name: "head", parent: 0 },
  { id: 2, name: "armUpperRight", parent: 0 },
  { id: 3, name: "armLowerRight", parent: 2 },
  { id: 4, name: "armUpperLeft", parent: 0 },
  { id: 5, name: "armLowerLeft", parent: 4 },
  { id: 6, name: "legRight", parent: 0 },
  { id: 7, name: "legLeft", parent: 0 },
];

export const origins = {};

const setupOrigins = () => {
  if (Object.keys(origins).length) return;
  const resource = resources.items.avatar.scene;

  resource.traverse((child) => {
    if (child.isMesh && rigData.find((rig) => rig.id === parseInt(child.name))) {
      const id = parseInt(child.name);
      if (isNaN(id)) return;
      const localPosition = new Vector3().copy(child.position);
      origins[id] = localPosition;
    }
  });
};

export default class Avatar {
  id = uuidv4();
  active = false;

  constructor({ customization, quality, outline = false } = {}) {
    this.quality = quality || "low";
    this.outline = outline;
    this.customization = customization || {
      skin: 1000,
    };

    setupOrigins();

    this.setupMaterial();
    this.setupGeometry();
    this.setupMesh();

    if (this.outline) {
      this.setupOutlineGeometry();
      this.setupOutlineMaterial();
      this.setupOutlineMesh();
    }
  }

  setupMaterial() {
    this.material = getMaterial();
  }

  setupOutlineMaterial() {
    this.outlineMaterial = new ShaderMaterial({
      vertexShader: outlineVertexShader,
      fragmentShader: outlineFragmentShader,
      side: BackSide,
      transparent: true,
      polygonOffset: true,
      polygonOffsetFactor: 1000,
      polygonOffsetUnits: 1000,
      uniforms: {
        uMatrices: { value: this.material.uniforms.uMatrices.value },
      },
    });
  }

  setupOutlineGeometry() {
    const scale = 1.03;

    this.outlineGeometry = this.createGeometry({ scale, updateUvs: false, smoothShading: true });
  }

  createGeometry({ scale = 1, updateUvs = true, smoothShading = false } = {}) {
    const skinData = customization.skin.items[this.customization.skin];

    let geometry = new BufferGeometry();

    let mergedPositions = [];
    let mergedNormals = [];
    let mergedUVs = [];
    let mergedIndices = [];
    let mergedIds = [];
    let offset = 0;

    this.resource.traverse((child) => {
      if (child.isMesh) {
        const id = parseInt(child.name);
        if (isNaN(id) || !rigData.find((rig) => rig.id === id)) return;

        let { geometry: ge } = child;

        if (smoothShading) {
          ge = ge.clone();
          ge.deleteAttribute("normal");
          ge = BufferGeometryUtils.mergeVertices(ge);
          ge.computeVertexNormals();
        }

        const positions = ge.getAttribute("position").array;
        const normals = ge.getAttribute("normal").array;
        const uvs = ge.getAttribute("uv").array;
        const indices = ge.getIndex().array;

        const matrix = new Matrix4();
        matrix.compose(
          new Vector3(...child.position),
          new Quaternion().setFromEuler(new Euler(...child.rotation)),
          new Vector3(...child.scale),
        );

        // Apply the scale using the normals to push the vertices outward
        for (let i = 0; i < positions.length; i += 3) {
          const vec = new Vector3(positions[i], positions[i + 1], positions[i + 2]);
          const norm = new Vector3(normals[i], normals[i + 1], normals[i + 2]);

          // Apply the scaling factor to customization geometry as well
          vec.addScaledVector(norm, scale - 1);

          mergedPositions.push(vec.x, vec.y, vec.z);
          mergedNormals.push(norm.x, norm.y, norm.z);
        }

        for (let i = 0; i < uvs.length; i += 2) {
          if (uvs[i + 1] < 0.875 && skinData?.uvOffset && updateUvs) {
            mergedUVs.push(uvs[i] + skinData.uvOffset[0], uvs[i + 1] + skinData.uvOffset[1]);
          } else {
            mergedUVs.push(uvs[i], uvs[i + 1]);
          }
        }

        for (let i = 0; i < indices.length; i++) {
          mergedIndices.push(indices[i] + offset);
        }

        for (let i = 0; i < positions.length / 3; i++) {
          mergedIds.push(id);
        }

        offset += positions.length / 3;
      }
    });

    const setupCustomization = (data) => {
      if (!data) return;
      const children = this.resource.children.filter((item) => item.name.startsWith(data));

      children.forEach((child) => {
        const rigId = parseInt(child.name.split("-")[1]) || 0;
        const origin = origins[rigId];

        let { geometry: ge } = child;

        if (smoothShading) {
          ge = ge.clone();
          ge.deleteAttribute("normal");
          ge = BufferGeometryUtils.mergeVertices(ge);
          ge.computeVertexNormals();
        }

        const positions = ge.getAttribute("position").array;
        const normals = ge.getAttribute("normal").array;
        const uvs = ge.getAttribute("uv").array;
        const indices = ge.getIndex().array;

        for (let i = 0; i < positions.length; i += 3) {
          const vec = new Vector3(positions[i], positions[i + 1], positions[i + 2]);
          const norm = new Vector3(normals[i], normals[i + 1], normals[i + 2]);

          vec.sub(origin);
          vec.sub(origin);
          vec.addScaledVector(norm, scale - 1);
          vec.add(origin);

          mergedPositions.push(vec.x, vec.y, vec.z);
          mergedNormals.push(norm.x, norm.y, norm.z);
        }

        for (let i = 0; i < uvs.length; i += 2) {
          mergedUVs.push(uvs[i], uvs[i + 1]);
        }

        for (let i = 0; i < indices.length; i++) {
          mergedIndices.push(indices[i] + offset);
        }

        for (let i = 0; i < positions.length / 3; i++) {
          mergedIds.push(rigId);
        }

        offset += positions.length / 3;
      });
    };

    setupCustomization(this.customization.top);
    setupCustomization(this.customization.bottom);
    setupCustomization(this.customization.hair);
    setupCustomization(this.customization.face);
    setupCustomization(this.customization.hat);
    setupCustomization(this.customization.back);

    geometry.setAttribute("position", new Float32BufferAttribute(mergedPositions, 3));
    geometry.setAttribute("normal", new Float32BufferAttribute(mergedNormals, 3));
    geometry.setAttribute("uv", new Float32BufferAttribute(mergedUVs, 2));
    geometry.setAttribute("id", new Float32BufferAttribute(mergedIds, 1));
    geometry.setIndex(mergedIndices);

    return geometry;
  }

  setupGeometry() {
    this.resource = resources.items.avatar.scene;

    this.geometry = this.createGeometry();

    this.rig = {};
    rigData.forEach((rig) => {
      if (!isNaN(rig.parent)) return;
      this.rig[rig.name] = new Limb({ ...rig, avatar: this });
    });

    rigData.forEach((rig) => {
      if (isNaN(rig.parent)) return;
      this.rig[rig.name] = new Limb({ ...rig, avatar: this, parent: this.rig[rigData[rig.parent].name] });
    });
  }

  setupMesh() {
    this.mesh = new Group();

    this.animatingMesh = new Mesh(this.geometry, this.material);
    this.animatingMesh.onBeforeRender = () => this.tick();

    this.mesh.add(this.animatingMesh);
  }

  setupOutlineMesh() {
    this.outlineMesh = new Mesh(this.outlineGeometry, this.outlineMaterial);

    this.mesh.add(this.outlineMesh);
  }

  destroy() {
    this.geometry.dispose();
    if (this.outlineGeometry) this.outlineGeometry.dispose();
    if (this.outlineMaterial) this.outlineMaterial.dispose();
    returnMaterial(this.material);
  }

  tick() {
    this.rig.armUpperRight.rotation.y = Math.sin(Date.now() * 0.001) * 0.5;
    this.rig.armLowerRight.rotation.z = Math.sin(Date.now() * 0.002) * 0.5;

    this.rig.armUpperLeft.rotation.z = Math.sin(Date.now() * 0.001) * 0.7;
    this.rig.armUpperLeft.rotation.x = Math.sin(Date.now() * 0.002) * 0.3;
    this.rig.armLowerLeft.rotation.y = Math.sin(Date.now() * 0.001) * 2;

    this.rig.body.rotation.z = Math.sin(Date.now() * 0.001) * 0.1;

    this.rig.head.rotation.z = Math.sin(Date.now() * 0.001) * 0.5;
    this.rig.head.rotation.x = Math.sin(Date.now() * 0.001) * 0.5;

    this.rig.legRight.rotation.z = Math.sin(Date.now() * 0.001) * 0.2;
    this.rig.legLeft.rotation.x = -Math.sin(Date.now() * 0.002) * 0.2;
    this.rig.legLeft.rotation.z = -Math.sin(Date.now() * 0.001) * 0.3;

    Object.keys(this.rig).forEach((key) => this.rig[key].tick());

    if (this.onTick) this.onTick();
  }
}
