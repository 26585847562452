import { Matrix4, Vector3, Quaternion, Euler } from "three";
import { origins } from "./Avatar";

export default class Limb {
  position = new Vector3();
  rotation = new Euler();
  scale = new Vector3(1, 1, 1);

  quaternionRotation = new Quaternion();

  constructor({ id, name, parent, avatar }) {
    this.id = id;
    this.name = name;
    this.avatar = avatar;
    this.parent = parent;

    this.matrix = this.avatar.material.uniforms.uMatrices.value[this.id];
    this.origin = origins[this.id];
  }

  tick() {
    this.quaternionRotation.setFromEuler(this.rotation);

    this.matrix.identity();

    this.position.add(this.origin);
    if (this.parent) this.position.sub(this.parent.origin);
    this.matrix.compose(this.position, this.quaternionRotation, this.scale);
    if (this.parent) this.position.add(this.parent.origin);
    this.position.sub(this.origin);

    if (this.parent) {
      this.matrix.premultiply(this.parent.matrix);
    }
  }
}
