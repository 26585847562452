varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vWorldPosition;

uniform sampler2D uColorMap;
uniform vec3 uLightPosition;
uniform vec3 uLightColor; 
uniform vec3 uAmbientColor; 
uniform float uOpacity;
uniform vec3 uShadowColor;
uniform float uShadowOpacity;

#define STEPS 2.0

void main() {
    gl_FragColor = texture2D(uColorMap, vUv);

    vec3 lightDirection = normalize(uLightPosition - vWorldPosition);
    float diffuseStrength = max(dot(vNormal, lightDirection), 0.0);
    
    //shadow
    float shadowStrength = 1. - smoothstep(0.1, 0.6, diffuseStrength);
    gl_FragColor.rgb = mix(gl_FragColor.rgb, uShadowColor, shadowStrength * uShadowOpacity);

    //ambient
    gl_FragColor.rgb += uAmbientColor;
}