import back from "./back/index.js";
import bottom from "./bottom/index.js";
import face from "./face/index.js";
import hair from "./hair/index.js";
import hat from "./hat/index.js";
import skin from "./skin/index.js";
import top from "./top/index.js";

export default {
  skin: {
    items: skin,
    canUndress: false,
  },
  back: {
    items: back,
    canUndress: true,
  },
  bottom: {
    items: bottom,
    canUndress: false,
  },
  face: {
    items: face,
    canUndress: true,
  },
  hair: {
    items: hair,
    canUndress: true,
  },
  hat: {
    items: hat,
    canUndress: true,
  },
  top: {
    items: top,
    canUndress: false,
  },
};
