varying vec2 vUv;  

uniform vec3 uLine;
uniform vec3 uFill;
uniform float uRadius;

#define gridSize 20.0  
#define lineThickness 0.03  

void main() {
    vec2 coord = vUv * gridSize;
    vec2 grid = abs(fract(coord - 0.5) - 0.5) / fwidth(coord * gridSize); 
    float line = min(grid.x, grid.y); 
    line = 1. - smoothstep(lineThickness, lineThickness + 0.1, line);

    vec3 color = mix(uLine, uFill, line);

    gl_FragColor = vec4(color, 1.0); 

    float distToCenter = distance(vUv, vec2(0.5));
    gl_FragColor.a = 1.0 - smoothstep(uRadius * 0.25, uRadius, distToCenter);
}