attribute float id;

varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vWorldPosition;

uniform mat4 uMatrices[8];
uniform vec3 uLightPosition;

void main() {
    vec4 worldPosition = vec4(position, 1.0);
    vec3 transformedNormal = normal;

    int index = int(id);
    mat4 matrix = uMatrices[index];

    worldPosition = matrix * worldPosition; 
    transformedNormal = (matrix * vec4(normal, 0.0)).xyz;

    vNormal = normalize(transformedNormal);
    vWorldPosition = worldPosition.xyz;  
    vUv = uv;

    gl_Position = projectionMatrix * modelViewMatrix * worldPosition;
}