import { Mesh, ShaderMaterial, PlaneGeometry, Color } from "three";
import fragmenShader from "../shaders/floor/fragment.glsl";
import vertexShader from "../shaders/floor/vertex.glsl";
import { scene } from "..";

let mesh,
  geometry,
  material,
  initialized = false;

export { mesh };

export const init = () => {
  if (initialized) return;
  initialized = true;

  setupMesh();
};

const setupMesh = () => {
  geometry = new PlaneGeometry(20, 20);

  material = new ShaderMaterial({
    vertexShader,
    fragmentShader: fragmenShader,
    transparent: true,
    uniforms: {
      uRadius: { value: 0.5 },
      uFill: { value: new Color(0x000000).convertLinearToSRGB() },
      uLine: { value: new Color(0x000000).convertLinearToSRGB() },
    },
  });

  mesh = new Mesh(geometry, material);
  mesh.visible = false;
  mesh.rotation.x = -Math.PI / 2;
  mesh.position.y = -0.001;

  scene.add(mesh);
};

export const show = ({ radius, fill, lineColor } = {}) => {
  init();

  mesh.visible = true;
  if (!isNaN(radius)) material.uniforms.uRadius.value = radius;
  if (fill) {
    material.uniforms.uFill.value.set(fill);
    material.uniforms.uFill.value.convertLinearToSRGB();
  }
  if (lineColor) {
    material.uniforms.uLine.value.set(lineColor);
    material.uniforms.uLine.value.convertLinearToSRGB();
  }
};

export const hide = () => {
  mesh.visible = false;
};
