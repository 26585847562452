import { Scene } from "three";

import { init as initRenderer } from "./renderer.js";
import { init as initCamera } from "./camera.js";

import { init as initAvatarMaterials } from "./shared/avatar/materials.js";
import { init as initFloor } from "./shared/floor.js";

let canvas, wrapper, initialized;

export { canvas, wrapper };

export const scene = new Scene();

export const init = () => {
  if (initialized) return;
  initialized = true;

  canvas = document.querySelector(".webgl-canvas");
  wrapper = document.querySelector(".webgl-wrapper");

  initAvatarMaterials();
  initFloor();

  initRenderer();
  initCamera();
};
