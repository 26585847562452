import { ShaderMaterial, Color, Vector3, Matrix4 } from "three";
import vertexShader from "../../shaders/avatar/vertex.glsl";
import fragmentShader from "../../shaders/avatar/fragment.glsl";
import resources from "../../../utils/resources";
import { rigData } from "./Avatar";
import { camera } from "../../camera";
import time from "~/utils/time";

const pool = [];
let active = [];

export const init = () => {
  time.on("render", tick);
};

export const getMaterial = () => {
  if (pool.length) {
    const material = pool.pop();
    active.push(material);
    return material;
  }

  const material = new ShaderMaterial({
    vertexShader,
    fragmentShader,
    transparent: true,
    uniforms: {
      uColorMap: { value: resources.items.colorMap },
      uMatrices: { value: rigData.map(() => new Matrix4()) },
      uLightPosition: { value: new Vector3(3, 5, 10) },
      uAmbientColor: { value: new Color(0x333333) },

      uCameraPosition: { value: camera.position },
      uAmbientColor: { value: new Color(0x000) },
      uFresnelColor: { value: new Color() },
      uShadowColor: { value: new Color(0x000) },
      uLightDirection: { value: new Vector3() },
      uLightColor: { value: new Color("#304553") },
      uShadowOpacity: { value: 1 },
      uOpacity: { value: 1 },
      uShadowOpacity: { value: 0.1 },
    },
  });

  active.push(material);

  return material;
};

export const returnMaterial = (material) => {
  if (!material || !material.isShaderMaterial) return;
  active = active.filter((m) => m.uuid !== material.uuid);
  pool.push(material);
};

const tick = () => {
  active.forEach((material) => {
    material.uniforms.uCameraPosition.value.copy(camera.position);
  });
};
