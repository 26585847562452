import { WebGLRenderer, SRGBColorSpace } from "three";
import { canvas, scene } from "./index.js";
import { camera } from "./camera.js";
import sizes from "~/utils/sizes";
import time from "~/utils/time";
import { active as activeGameData } from "./games.js";

let renderer,
  needsUpdate = true;

export { renderer };

export const init = () => {
  renderer = new WebGLRenderer({
    canvas,
    antialias: true,
    stencil: true,
    transparent: true,
    powerPreference: "high-performance",
  });

  renderer.outputColorSpace = SRGBColorSpace;
  renderer.setClearColor("#000", 0);

  resize();

  sizes.on("resize", resize);
  time.on("tick", tick);
};

const resize = () => {
  if (!renderer) return;
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  //renderer.setPixelRatio(2);
};

const tick = () => {
  const isEveryChildInisible = scene.children.every((child) => (!child.isMesh ? false : !child.visible));
  if (scene.children.length > 0 && !isEveryChildInisible) needsUpdate = true;
  if (!sizes.visible || !needsUpdate) return;
  time.emit("render");

  const c = activeGameData?.scene?.camera || camera;
  renderer.render(scene, c);
  needsUpdate = true;
  if (scene.children.length <= 0 || isEveryChildInisible) needsUpdate = false;

  //console.log(renderer.info.memory);
};
