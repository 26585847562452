import { init as initWebGL, scene } from ".";
import games from "~~/games";

export let active;

export const setupGame = (slug, glManager, controller) => {
  if (!slug || !games[slug] || !glManager) return;
  initWebGL();

  active = { slug, ...glManager };
  if (active.setup.init) active.setup.init();
  if (controller?.signal?.aborted) return;

  if (active.scene?.group) scene.add(active.scene.group);

  return active;
  //wrapper.style.zIndex = "11";
};

export const reset = (slug) => {
  if (!active || active.slug !== slug) return;
  if (active.setup?.destroy) active.setup.destroy();

  //wrapper.style.zIndex = "0"

  //TBD: destroy all objects inside active, aswell as camera etc.
  if (active.scene?.group) scene.remove(active.scene.group);

  active = null;
};
